import React, { useEffect, useState } from "react";
import "./FoundationFooter.css";
import { Box, Typography, Button, Avatar, Grid, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  fndFooterLogo,
} from "./../Components/Images/Images";
import FoundationContactUsPage from "./FoundationContactUsPage";


const FoundationFooter = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dialogOpen, setDailogOpen] = useState(false);
  const navigate = useNavigate();


  function topFunction() {
    document.documentElement.scrollTop = 0;
  }

  const handleOpenContact = () => {
    setDailogOpen(true)
  }

  const handleCloseDialog =()=>{
    setDailogOpen(false)
  }

  return (
    <Box className="fnd-footer" pt={{ xs: 0, sm: 2, md: 8 }}>
      <Box className="container">
        <Box className="fnd-footer-menu">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4.8} lg={4}>
              <Box className="footer-explore-mobile" sx={{ maxWidth: '341px', margin: '0 auto' }}>
                <Button
                  onClick={() => {
                    window.open('https://bridge.race.foundation/', '_blank')
                  }}
                  sx={{ my: 4, width: '100%' }} className='btn-rounded btn-golden btn-large'>Bridge now to RACE</Button>
              </Box>
              <Box className="footer-about" pr={{ sm: 4, md: 5 }}>
                <Box className="ftra-logo">
                  <Avatar
                    variant="square"
                    src={fndFooterLogo}
                    alt="Icon"
                    sx={{ width: "100%", maxWidth: "237px", height: "auto" }}
                  ></Avatar>
                </Box>
                <Typography>Designed with the OP Stack, we bring real-world, cash-flow-yielding assets to the heart of Ethereum.</Typography>
                <Box className="ftr-visitBridge">
                  <Box component={'span'}>Bridge now to RACE</Box>
                  <Button
                    onClick={() => {
                      window.open('https://bridge.race.foundation/', '_blank')
                    }}

                    className=' btn-rounded btn-golden btn-radius-10 mobile-btn'>Visit Bridge</Button>
                </Box>
              </Box>
            </Grid>
            {/* previous xs={6} sm={3} md={1.8} lg={2}  */}
            <Grid item xs={6} sm={3} md={2.1} lg={2.6}>
              <Box className="footer-colum">
                <Typography className="title">GOVERNANCE</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link to="/governance#vhouse" > vHouse </Link>
                  </Box>
                  <Box onClick={topFunction} className="menu-item">
                    <Link to="/governance#ahouse" > aHouse</Link>
                  </Box>
                  <Box className="menu-item">
                    <Link to="/governance#council"> Executive Council </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3} md={2.1} lg={2.6}>
              <Box className="footer-colum">
                <Typography className="title">ECONOMY</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link
                      to="https://raceecosystem.com/"
                      target="_blank"
                    >
                      Community
                    </Link>
                  </Box>
                  <Box onClick={topFunction} className="menu-item">
                    <Link
                      to="https://apps.apple.com/us/app/race-wallet/id6572301261"
                      target="_blank"
                    >
                      RACE Wallet
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3} md={2.1} lg={2.6}>
              <Box className="footer-colum">
                <Typography className="title">DEVELOPERS</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link
                      to="https://raceecosystem.gitbook.io/docs/"
                      target="_blank"
                    >
                      Documentation
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link
                      to="https://racescan.io/"
                      target="_blank"
                    >
                      Block Explorer (Mainnet)
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link
                      aria-disabled={true}
                      to="#"
                    >
                      Status (coming soon)
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link to="#">
                      Bug Bounty (coming soon)
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link to="https://github.com/RACE-Ecosystem"
                      target="_blank">
                      Github
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={6} sm={3} md={1.8} lg={2}>
              <Box className="footer-colum">
                <Typography className="title">MORE</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                      // to="/project-proposer"
                      to="#"
                    >
                      Industry Adoption
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                      //  to="/service-provider"
                      to="#"
                    >
                      Team
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link
                      onClick={topFunction}
                      to="#"
                    >
                      Security
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link
                      onClick={topFunction}
                      to="#"
                    >
                      Contact
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <Box className="fnd-footer-copyright">
          <Box className="ftra-copyright-logo">
            <Avatar
              variant="square"
              src={fndFooterLogo}
              alt="Icon"
              sx={{ width: "100%", maxWidth: "230px", height: "auto" }}
            ></Avatar>
          </Box>
          <Box className="tac" my={1}>
            <Stack direction={{xs:'column',sm:'row'}} alignItems={'center'} justifyContent={'center'} flexWrap="wrap" spacing={{xs: 1, sm: 2}}>
              <Box  className="menu-item">
                <Button className="btn btn-theme-primary" onClick={handleOpenContact}>
                  Contact Support
                </Button>
              </Box>
              <Box  className="menu-item">
                <Link
                  onClick={topFunction}
                  to="/terms-and-conditions"
                >
                  Terms and Conditions
                </Link>
              </Box>
              <Box className="menu-item">
                <Link
                  onClick={topFunction}
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box  className="menu-item">
                <Link
                  onClick={topFunction}
                  to="/blog"
                >
                  News
                </Link>
              </Box>
            </Stack>
          </Box>
          <Typography className="des">© 2024 RACE. All rights reserved.</Typography>
        </Box>
      </Box>
      <FoundationContactUsPage open={dialogOpen}
        handleCloseDialog={handleCloseDialog} />
    </Box>
  );
};

export default FoundationFooter;
